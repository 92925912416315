import React, { useEffect, useState } from 'react'

import './switch.scss'

const Switch = ({ label, initiale, devientOn, devientOff }) => {

    let i = true

    useEffect(() => {
        i = false
    }, [])

    const [position, setPosition] = useState(initiale)

    useEffect(() => {
        if (i) {
            if (position) {
                devientOn()
            } else {
                devientOff()
            }
        }
    }, [position])

    return (
        <div className='switch'>
            <div
                className={`bouton ${initiale ? "true" : "false"}`}
                onClick={() => setPosition(p => !p)}
            >
                <div
                    className='curseur'
                    style={{
                        transform: `translateX(${initiale ? `27px` : `0px`})`
                    }}
                />
            </div>
            <div className='label'>{label}</div>
        </div>
    )
}

export default Switch